.App {
  text-align: center;
  background-image: linear-gradient(90deg, #283e4a, white, #e8fdfe);
}

.CustomizedImageBoxOne {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../src/images/sideBar_1.jpg");
}
.CustomizedImageBoxTwo {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../src/images/sideBar_2.jpg");
}

.CustomizedImageBoxThree {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../src/images/sideBar_3.jpg");
}

.CustomizedImageBoxFour {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../src/images/sideBar_4.jpg");
}

.about-us-hero {
  background-image: url("../src/images/overlay_1.svg"), url("../src/images/sideBar_1.jpg");
}
.landing-pod-one {
  background-image: url("../src/images/resource-one.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  height: 100px;
  box-shadow: 1px 1px 3px black;
}
.landing-pod-two {
  background-image: url("../src/images/resource-two.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  /* height: 100px; */
  box-shadow: 1px 1px 3px black;
}
.landing-pod-three {
  background-image: url("../src/images/resource-three.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  height: 100px;
  box-shadow: 1px 1px 3px black;
}
.landing-pod-four {
  background-image: url("../src/images/resource-four.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  height: 100px;
  box-shadow: 1px 1px 3px black;
}

.swiper-box {
  /* border: 20px solid #daf0d7; */
  height: 95%;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgb(41 61 74);
  color: lightgreen;
}

.landing-badge-two {
  background-image: url("../src/images/resource-five.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  box-shadow: 1px 1px 3px black;
}

.landing-badge-three {
  background-image: url("../src/images/sideBar_1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  box-shadow: 1px 1px 3px black;
}

.forms-hero {
  background-image: url("../src/images/overlay_1.svg"), url("../src/images/forms.png");
}

.policy-hero {
  background-image: url("../src/images/overlay_1.svg"), url("../src/images/policy-banner.jpg");
}
.landing-header {
  text-align: center;
}
.mobile {
  background-position: center;
  background-image: url("../src/images/mobile-hummingbirdosc.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.about-me {
  background-image: url("../src/images/about-me.jpg");
  background-repeat: no-repeat;
  background-size: contain;
}
.blog-carousel {
  background-image: url("../src/images/blog.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  height: 450px;
  width: 220px;
  box-shadow: 10px 10px 10px lightgray;
}
.landing-carousel {
  background-image: url("../src/images/landing.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  height: 560px;
  width: 285px;
  box-shadow: 10px 10px 10px lightgray;
}

.invoice-carousel {
  background-image: url("../src/images/invoice-full.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  height: 450px;
  width: 220px;
  box-shadow: 10px 10px 10px lightgray;
}
.home-carousel {
  background-image: url("../src/images/mobile_home.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  height: 320px;
  box-shadow: 10px 10px 10px lightgray;
}
.qr-carousel {
  background-image: url("../src/images/qrcode.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  height: 320px;
  /* width: 180px; */
  box-shadow: 10px 10px 10px lightgray;
}

#video {
  position: absolute;
  width: inherit;
  height: inherit;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../src/images/overlay_1.svg"), url("../src/images/2.jpg");
}

.RowDebit td {
  color: red;
}
.About-us {
  background-image: linear-gradient(to bottom, white, white, #a5d6a7);
  /* height: 100vh; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.tr-height td {
  max-height: 0px !important;
  color: transparent;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: darkblue;
}

.App-link {
  color: #61dafb;
}
